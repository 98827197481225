.mainContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 64px;
}

.dataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 900px;
}

.header {
  text-align: center;
  font-size: 32px;
  font-weight: 600;
}

.bio {
  text-align: center;
  color: gray;
  margin-top: 16px;
}

.waveButton {
  cursor: pointer;
  margin-top: 16px;
  padding: 10px 50px;
  border: 0;
  border-radius: 5px;

}

.message {
  box-sizing: border-box;
  padding: 10px 20px;
}

.box {
  background-color: #444;
  color: #fff;
  border-radius: 0.75em;
  padding: 1.5em;
  overflow-wrap: break-word;
}

.box:nth-child(even) {
  background-color: #ccc;
  color: #000;
}

.wrapper {
  /* width: 600px; */
  display: grid;
  grid-gap: 1.5em;
  grid-template-columns: repeat(3, 15em);
  grid-template-rows: auto auto auto;
  grid-auto-flow: row;
}

.date {
  font-size: small;
}
